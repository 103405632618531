import React, {PropsWithChildren} from 'react';

import AuthContext from './AuthContext';
import useProvideAuth from "./useProvideAuth";

export const AuthProvider: React.FC<PropsWithChildren> = (
    {children}
) => {
    const auth = useProvideAuth();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
