import {BackendApi} from "./httpClient/backendApi"

export const createEvent = async (title: string): Promise<string | null> => {
    const response = await BackendApi.getInstance().instance
        .post<{ id: string }>(
            `/events`,
            {title: title}
        );

    if (!response.data) {
        return null
    }

    return response.data.id
}
