import React from "react";
import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    IconButton,
    ButtonGroup,
} from '@chakra-ui/react'
import {RepeatIcon} from "@chakra-ui/icons";
import {Event} from "../../../store/models";
import {rematchLastRound} from "../../../usecases/backend/rematchLastRound";


interface RematchRoundPopoverProps {
    eventId: string;
    updateEvent: (event: Event | null) => void;
}

export const RematchRoundPopover: React.FC<RematchRoundPopoverProps> = ({eventId, updateEvent}) => {
    return (
        <Popover>
            {({onClose}) => (
                <>
                    <PopoverTrigger>
                        <IconButton
                            variant='outline' size='lg' aria-label='Rematch Round'
                            colorScheme='teal'
                            icon={<RepeatIcon/>}
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
                        <PopoverArrow/>
                        <PopoverCloseButton/>
                        <PopoverBody>
                            Are you sure you want to rematch this round?
                        </PopoverBody>
                        <PopoverFooter display='flex' justifyContent='flex-end'>
                            <ButtonGroup size='sm'>
                                <Button variant='outline' colorScheme='teal' onClick={() => {
                                    rematchLastRound(eventId).then(updateEvent);
                                    onClose();
                                }}>
                                    Rematch
                                </Button>
                                <Button variant='ghost' colorScheme='red' onClick={onClose}>
                                    Close
                                </Button>
                            </ButtonGroup>
                        </PopoverFooter>
                    </PopoverContent>
                </>
            )}
        </Popover>
    )
}
