import React from 'react';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";

interface NavigationProps {
    path: { name: string, href: string }[];
}

export const Navigation: React.FC<NavigationProps> = ({path}) => {
    return (
        <Breadcrumb fontSize='2xl'>
            {path.map(
                (item) => {
                    return (
                        <BreadcrumbItem key={item.name}>
                            <BreadcrumbLink as={RouterLink} to={item.href}>
                                {item.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    )
                }
            )}
        </Breadcrumb>
    )
}
