import React from 'react';
import {useEffect, useState} from 'react'
import {ChakraProvider} from "@chakra-ui/react";
import {
    Container,
    Divider,
    Flex,
    SimpleGrid,
    Stack
} from "@chakra-ui/react";
import {useLoaderData} from "react-router-dom";

import {Navigation} from "../../components/Navigation/Navigation";
import {League} from "../../store/models";
import {PermissionProvider} from "../../utils/PermissionProvider/PermissionProvider";
import useAuth from "../../utils/AuthProvider/useAuth";
import {getLeague} from "../../usecases/backend/getLeague";
import {ScoreboardCard} from "../../components/Main/ScoreboardCard/ScoreboardCard";

export const LeaguePage: React.FC = () => {
    const id = useLoaderData() as string;
    const [league, setLeague] = useState<League>();
    const auth = useAuth();

    const updateLeague = (league: League | null) => {
        if (league)
            setLeague(league)
    }

    useEffect(() => {
        document.title = "League - Mass Destruction";
        getLeague(id)
            .then(updateLeague)
    }, [id]);

    const getScoreboard = () => {
        if (!league) {
            return null
        }

        return (
            <ScoreboardCard
                leagueId={league.id}
                startDate={new Date(league.start_date)}
                endDate={new Date(league.end_date)}
                scoreboard={league.scoreboard}
            />
        )
    }

    return (
        <ChakraProvider>
            <PermissionProvider fetchPermission={(perm) => {
                console.log(auth.user?.username)
                switch (perm) {
                    case 'owner': {
                        return Promise.resolve(!!league && league?.owner === auth.user?.username);
                    }
                    default: {
                        return Promise.resolve(false);
                    }
                }
            }}>
                <Container maxW='container.xl'>
                    <Stack py={2}>
                        <Flex align='end' gap={2}>
                            {
                                league
                                    ?
                                    <Navigation path={[{name: "Leagues", href: "/leagues"}, {name: league.title, href: "."}]}/>
                                    : <Navigation path={[{name: "Leagues", href: "/leagues"}]}/>
                            }
                        </Flex>
                        <Divider/>
                    </Stack>
                    <Stack spacing={2}>
                        <SimpleGrid spacing={2}>
                            {getScoreboard()}
                        </SimpleGrid>
                    </Stack>
                </Container>
            </PermissionProvider>
        </ChakraProvider>
    )
}
