import {BackendApi} from "./httpClient/backendApi";
import {AxiosError} from "axios";

export const login = async (username: string, password: string): Promise<null> => {
    const instance = BackendApi.getInstance()

    try {
        const response = await instance.instance
            .post(
                '/auth/login',
                {username: username, password: password},
                {headers: {'content-type': 'application/x-www-form-urlencoded'}},
            )

        if (!response.data) {
            return null;
        }
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response) {
                throw error.response.data.detail;
            }
        }
    }

    return null;
}
