import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, Flex,
} from '@chakra-ui/react'

import {LeaguePlayer} from "../../../store/models";

interface ScoreboardCardProps {
    leagueId: string;
    startDate: Date;
    endDate: Date;
    scoreboard: LeaguePlayer[];
}

export const ScoreboardCard: React.FC<ScoreboardCardProps> = (
    {scoreboard}
) => {
    const getTable = () => {
        return (
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th isNumeric>Place</Th>
                            <Th>Player</Th>
                            <Th isNumeric>Points</Th>
                            <Th isNumeric>Wins</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            scoreboard.map((player) => {
                                return (
                                    <Tr key={player.place}>
                                        <Td isNumeric>{player.place}</Td>
                                        <Td>{player.username}</Td>
                                        <Td isNumeric>{player.score}</Td>
                                        <Td isNumeric>{player.wins}</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Card variant='outline'>
            <CardHeader>
                <Flex>
                    <Heading size='md'>Scoreboard</Heading>
                </Flex>
            </CardHeader>
            <CardBody>
                {getTable()}
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    )
}
