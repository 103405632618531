import React from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuGroup,
    MenuItem,
    MenuDivider
} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import {useLocation, useNavigate, Link as ReactRouterLink} from "react-router-dom";

import Logo from "../../public/magic-logo.webp"
import useAuth from "../../utils/AuthProvider/useAuth";

export const Header: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {user, isLoggedOn, signout} = useAuth();

    const getMenu = () => {
        return (
            <Menu>
                <MenuButton as={Button} colorScheme='teal'>
                    Profile
                </MenuButton>
                <MenuList>
                    <MenuGroup title='Profile'>
                        <MenuItem>{user?.username}</MenuItem>
                    </MenuGroup>
                    <MenuDivider/>
                    <Button
                        mx={2}
                        colorScheme='red'
                        variant='ghost'
                        onClick={signout}
                    >
                        Logout
                    </Button>
                </MenuList>
            </Menu>
        )
    }

    return (
        <Box as='header' py={2} bg={'gray.900'}>
            <Container maxW='container.xl'>
                <Flex align='center' justify='space-between'>
                    <Menu>
                        <MenuButton aria-label='Options'>
                            <HamburgerIcon color={'white'}/>
                        </MenuButton>
                        <MenuList>
                            <MenuItem as={ReactRouterLink} to='/'>
                                Events
                            </MenuItem>
                            <MenuItem as={ReactRouterLink} to='/leagues'>
                                Leagues
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <Image mr={4} src={Logo} alt='logo' maxH='50px' objectFit='cover'/>
                    {
                        isLoggedOn === true &&
                        getMenu()
                    }
                    {
                        isLoggedOn === false &&
                        <Button
                            colorScheme='green'
                            variant='solid'
                            onClick={
                                () => {
                                    navigate('/login', {state: {from: location}});
                                }
                            }
                        >
                            Login
                        </Button>
                    }
                </Flex>
            </Container>
        </Box>
    )
}
