import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Card, CardHeader, CardBody, Heading, LinkBox, Text, VStack} from "@chakra-ui/react"

interface LeagueCardProps {
    id: string;
    title: string;
    owner: string;
    startDate: Date;
    endDate: Date;
}

export const LeagueCard = ({id, title, owner, startDate, endDate}: LeagueCardProps) => {
    return (
        <LinkBox as={RouterLink} to={`/leagues/${id}`}>
            <Card align='center' variant='outline' _hover={{shadow: 'md'}}>
                <CardHeader>
                    <VStack>
                        <Heading size='md'>{title} </Heading>
                    </VStack>
                </CardHeader>
                <CardBody>
                    <Text align='center'> Hosted by <Text as='b'>{owner}</Text> </Text>
                    <Text align='center'>Starts: {startDate.toLocaleDateString("en-GB", {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })}</Text>
                    <Text align='center'>Ends: {endDate.toLocaleDateString("en-GB", {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })}</Text>
                </CardBody>
            </Card>
        </LinkBox>
    )
}
