import React, {useEffect} from "react";
import {Field, Form, Formik, FormikErrors, FormikHelpers, FormikProps} from "formik";
import {CardFooter, ChakraProvider} from "@chakra-ui/react";
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Button,
    Heading,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
} from "@chakra-ui/react";
import {useNavigate, useLocation} from "react-router-dom";

import useAuth from "../../utils/AuthProvider/useAuth";

export const LoginPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/";
    const auth = useAuth();

    useEffect(() => {
        document.title = "Login - Mass Destruction";
    })

    const getLoginForm = () => {
        interface FormValues {
            username: string
            password: string
        }

        const validateForm = (value: FormValues) => {
            let error: FormikErrors<FormValues> = {};
            if (!value.username) {
                error.username = 'Username is required'
            }
            if (!value.password) {
                error.password = 'Password is required'
            }
            return error
        }

        return (
            <Formik
                initialValues={{username: '', password: ''}}
                onSubmit={(
                    values: FormValues,
                    {setSubmitting, setErrors}: FormikHelpers<FormValues>
                ) => {
                    auth.signin(values.username, values.password)
                        .then(() => {
                            navigate(from, {replace: true});
                        }).catch(error => {
                        if (typeof error === 'string') {
                            setErrors({password: error});
                        }
                    }).finally(() => {
                        setSubmitting(false);
                    })
                }}
                validate={validateForm}
            >
                {(props: FormikProps<FormValues>) => (
                    <Form>
                        <Stack spacing={5}>
                            <Field name='username'>
                                {({field, form}: any) => (
                                    <FormControl isRequired isInvalid={form.errors.username}>
                                        <FormLabel>Username</FormLabel>
                                        <Input {...field} placeholder='Username'/>
                                        <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='password'>
                                {({field, form}: any) => (
                                    <FormControl isRequired isInvalid={form.errors.password}>
                                        <FormLabel>Password</FormLabel>
                                        <Input {...field} type='password' placeholder='Password'/>
                                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Button
                                variant="outline"
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Sign In
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <ChakraProvider>
            <Container maxW='container.lg'>
                <Flex align='center' justify='center' py={5}>
                    <Card w='lg' variant='elevated' px={5} py={2}>
                        <CardHeader>
                            <Heading size='md'>Sign In</Heading>
                        </CardHeader>
                        <CardBody>
                            {getLoginForm()}
                        </CardBody>
                        <CardFooter>
                            Need an account? Sign Up
                        </CardFooter>
                    </Card>
                </Flex>
            </Container>
        </ChakraProvider>
    )
}
