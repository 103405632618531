import {BackendApi} from "./httpClient/backendApi";
import {League} from "../../store/models";

export const getLeague = async (leagueId: string): Promise<League | null> => {
    const response = await BackendApi.getInstance().instance
        .get<{ league: League }>(
            `/leagues/${leagueId}`,
        );

    if (!response.data) {
        return null;
    }

    return response.data.league;
}
