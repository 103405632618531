import React from 'react';
import {Auth} from "./Types";

const defaultValue: Auth = {
    user: null,
    isLoggedOn: null,
    signin: () => {
        return Promise.resolve(null)
    },
    signout: () => {
        return Promise.resolve()
    }
}

const AuthContext = React.createContext<Auth>(defaultValue);

export default AuthContext;
