import React, {useEffect, useState} from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Stack,
    Flex,
    Heading,
    IconButton,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    SimpleGrid,
} from '@chakra-ui/react'
import {AddIcon} from "@chakra-ui/icons";

import {Event, Pod, Round} from "../../../store/models";
import {PodCard} from "../PodCard/PodCard";
import {ByesCard} from "../ByesCard/ByesCard"
import {updateRoundResults} from "../../../usecases/backend/updateRoundResults";
import {RematchRoundPopover} from "../RematchRoundPopover/RematchRoundPopover";
import {startNewRound} from "../../../usecases/backend/startNewRound";
import {Restricted} from "../../../utils/PermissionProvider/Restricted";

interface RoundsCardProps {
    eventId: string;
    rounds: Round[];
    isEditable?: boolean;
    updateEvent: (event: Event | null) => void;
}

export const RoundsCard: React.FC<RoundsCardProps> = ({eventId, rounds, isEditable, updateEvent}) => {
    const [scores, setScores] = useState<Round[]>(rounds);

    useEffect(() => {
        setScores(rounds)
    }, [rounds])

    const updateRound = (roundNumber: number, pod: Pod | null): void => {
        if (pod) {
            let copy: Round[] = Object.assign([], scores)
            copy[roundNumber - 1].pods[pod.number - 1] = pod
            setScores(copy)
            updateRoundResults(eventId, scores[roundNumber - 1]).then(updateEvent)
        }
    }

    const getPodsList = (round: Round, isEditable: boolean) => {
        return round.pods.map(
            (pod) =>
                <PodCard
                    updateScore={(p: Pod | null) => {
                        updateRound(round.number, p)
                    }}
                    key={pod.number}
                    pod={pod}
                    isEditable={isEditable}
                />
        )
    }
    const getRoundTabs = () => {
        return (
            <Tabs variant='soft-rounded' colorScheme='teal'>
                <TabList overflow='auto'>
                    <Flex align='center' gap={2}>
                        {
                            rounds.map(
                                (round) =>
                                    <Tab key={round.number}>
                                        Round {round.number}
                                    </Tab>
                            )
                        }
                        <Restricted to='owner'>
                            {
                                isEditable && <IconButton
                                    variant='ghost' size='sm' aria-label='Start new Round'
                                    colorScheme='teal'
                                    icon={<AddIcon/>}
                                    borderRadius='50%'
                                    onClick={() => {
                                        startNewRound(eventId)
                                            .then(updateEvent)
                                            .catch((error) => {
                                                    alert(error.response.data.detail)
                                                }
                                            )
                                    }}
                                />
                            }
                        </Restricted>
                    </Flex>
                </TabList>
                <TabPanels>
                    {
                        rounds.map(
                            (round) =>
                                <TabPanel key={round.number}>
                                    <Stack spacing={4}>
                                        {!!round.bye.players.length && <ByesCard players={round.bye.players}/>}
                                        <Restricted to='owner' fallback={
                                            <SimpleGrid columns={[1, null, 3]} spacing={5}>
                                                {getPodsList(round, false)}
                                            </SimpleGrid>
                                        }>
                                            <SimpleGrid columns={[1, null, 3]} spacing={5}>
                                                {getPodsList(round, !!isEditable && round.number > rounds.length - 2)}
                                            </SimpleGrid>
                                        </Restricted>
                                        <Restricted to='owner'>
                                            {
                                                !!isEditable && round.number === rounds.length && <RematchRoundPopover
                                                    eventId={eventId}
                                                    updateEvent={updateEvent}
                                                />
                                            }
                                        </Restricted>
                                    </Stack>
                                </TabPanel>
                        )
                    }
                </TabPanels>
            </Tabs>
        )
    }

    return (
        <Card variant='outline'>
            <CardHeader>
                <Heading size='md'>Rounds</Heading>
            </CardHeader>
            <CardBody>
                {getRoundTabs()}
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    )
}
