import {BackendApi} from "./httpClient/backendApi";
import {Event} from "../../store/models";

export const removePlayerFromEvent = async (eventId: string, username: string): Promise<Event | null> => {
    const response = await BackendApi.getInstance().instance
        .delete<{ event: Event }>(
            `/events/${eventId}/players`,
            {
                data: {username: username},
            },
        );

    if (!response.data) {
        return null;
    }

    return response.data.event;
}
