import {useState, useEffect} from 'react';

import {me} from '../../usecases/backend/me';
import {login} from '../../usecases/backend/login';
import {logout} from '../../usecases/backend/logout';
import {User} from "../../store/models";
import {Auth} from "./Types";

const useProvideAuth = (): Auth => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoggedOn, setIsLoggedOn] = useState<boolean | null>(null);

    const signin = (username: string, password: string) => {
        return login(username, password)
            .then(
                async () => {
                    const user = await me();
                    if (user) {
                        setUser(user);
                        setIsLoggedOn(true);
                    }
                    return user;
                }
            );
    };

    const signout = () => {
        return logout()
            .then(() => {
                setUser(null);
                setIsLoggedOn(false);
            });
    };

    useEffect(() => {
        me()
            .then((user) => {
                if (user) {
                    setUser(user);
                    setIsLoggedOn(true);
                } else
                    setIsLoggedOn(false);
            })
            .catch(() => setIsLoggedOn(false))
    }, [])

    return {
        user,
        isLoggedOn,
        signin,
        signout,
    };
}

export default useProvideAuth;
