import React from "react";
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"
import {Field, Form, Formik, FormikHelpers, FormikProps, FormikErrors} from "formik"
import {createEvent} from "../../../usecases/backend/createEvent";
import {useNavigate} from "react-router-dom";

interface CreateEventModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CreateEventModal: React.FC<CreateEventModalProps> = ({isOpen, onClose}) => {
    const navigate = useNavigate();

    const getNameForm = () => {
        interface FormValues {
            eventName: string
        }

        const validateForm = (value: FormValues) => {
            let error: FormikErrors<FormValues> = {};
            if (!value.eventName) {
                error.eventName = 'Name is required'
            }
            return error
        }

        return (
            <Formik
                initialValues={{eventName: ''}}
                onSubmit={(
                    values: FormValues,
                    {setSubmitting}: FormikHelpers<FormValues>
                ) => {
                    setTimeout(() => {
                        createEvent(values.eventName.trim()).then((id) => {
                            if (id) {
                                navigate(`/events/${id}`);
                            }
                        })
                        setSubmitting(false)
                        onClose()
                    }, 1000)
                }}
                validate={validateForm}
            >
                {(props: FormikProps<FormValues>) => (
                    <Form>
                        <ModalBody>
                            <Field name='eventName'>
                                {({field, form}: any) => (
                                    <FormControl isRequired isInvalid={form.errors.eventName}>
                                        <FormLabel>Event Name</FormLabel>
                                        <Input {...field} placeholder='Event Name'/>
                                        <FormErrorMessage>{form.errors.eventName}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                mr={3}
                                variant="outline"
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Submit
                            </Button>
                            <Button variant='ghost' colorScheme='red' onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>New event</ModalHeader>
                <ModalCloseButton/>
                {getNameForm()}
            </ModalContent>
        </Modal>
    )
}
