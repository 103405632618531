import React, {PropsWithChildren, ReactNode} from 'react';

import {Permission} from './Types';
import usePermission from './usePermission';

interface RestrictedProps {
    to: Permission;
    fallback?: ReactNode;
    loadingStub?: ReactNode;
}

export const Restricted: React.FC<PropsWithChildren<RestrictedProps>> = (
    {to, fallback, loadingStub, children}
) => {
    const [loading, allowed] = usePermission(to);

    if (loading) {
        console.log("Loading");
        return <>{loadingStub}</>;
    }

    if (allowed) {
        console.log("Allowed ", to);
        return <>{children}</>;
    }

    return <>{fallback}</>;
}
