import React from 'react';
import {Box, Heading} from '@chakra-ui/react'
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Stack,
} from '@chakra-ui/react'

interface ByesProps {
    players: string[];
}

export const ByesCard = ({players}: ByesProps) => {
    const getTable = () => {
        return (
            <TableContainer>
                <Table variant='simple'>
                    <Tbody>
                        {players.map(
                            (player) =>
                                <Tr key={player}>
                                    <Td> {player} </Td>
                                </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={5} borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Stack spacing={2}>
                <Heading size='md'> Byes </Heading>
                {getTable()}
            </Stack>
        </Box>
    )
}
