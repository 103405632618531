import {BackendApi} from "./httpClient/backendApi";

import {User} from "../../store/models";

export const me = async (): Promise<User | null> => {
    const response = await BackendApi.getInstance().instance
        .get<User>('/users/me');

    if (!response.data) {
        return null;
    }

    return response.data;
}
