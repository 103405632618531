import React, {PropsWithChildren} from 'react';

import {Permission} from './Types';
import PermissionContext from './PermissionContext';

type PermissionCache = {
    [key: string]: boolean;
}

interface PermissionProviderProps {
    fetchPermission: (perm: Permission) => Promise<boolean>
}

export const PermissionProvider: React.FC<PropsWithChildren<PermissionProviderProps>> = (
    {fetchPermission, children}
) => {
    const cache: PermissionCache = {};

    const isAllowedTo = async (permission: Permission): Promise<boolean> => {
        if(Object.keys(cache).includes(permission)){
            return cache[permission];
        }
        return await fetchPermission(permission);
    }

    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>
}
