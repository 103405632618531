import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Badge, Card, CardHeader, CardBody, Center, Flex, Heading, LinkBox, Text, VStack} from "@chakra-ui/react"

interface EventCardProps {
    id: string;
    title: string;
    owner: string;
    createdAt: Date;
    status: string;
}

export const EventCard = ({id, title, owner, createdAt, status}: EventCardProps) => {
    const badgeColorMap: { [status: string]: string } = {
        "created": "blue",
        "started": "green",
        "finished": "green",
        "canceled": "red",
    };

    return (
        <LinkBox as={RouterLink} to={`/events/${id}`}>
            <Card align='center' variant='outline' _hover={{shadow: 'md'}}>
                <CardHeader>
                    <VStack>
                        <Heading size='md'>{title} </Heading>
                        <Badge ml={1} variant='solid' colorScheme={badgeColorMap[status]}>{status}</Badge>
                    </VStack>
                </CardHeader>
                <CardBody>
                    <Text align='center'> Hosted by <Text as='b'>{owner}</Text> </Text>
                    <Text align='center'>{createdAt.toLocaleDateString("en-GB", {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })}</Text>
                </CardBody>
            </Card>
        </LinkBox>
    )
}
