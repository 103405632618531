import React from "react";
import {useEffect, useState} from "react"
import {ChakraProvider, useDisclosure} from "@chakra-ui/react";
import {Container, Divider, Flex, IconButton, SimpleGrid, Stack} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";

import {LeaguePreview} from "../../store/models";
import {CreateEventModal} from "../../components/Main/CreateEventModal/CreateEventModal";
import {getAllLeagues} from "../../usecases/backend/getAllLeagues";

import {Navigation} from "../../components/Navigation/Navigation";
import useAuth from "../../utils/AuthProvider/useAuth";
import {LeagueCard} from "../../components/Main/LeagueCard/LeagueCard";

export const LeaguesPage: React.FC = () => {
    const [leagues, setLeagues] = useState<LeaguePreview[]>([]);
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isLoggedOn} = useAuth();

    useEffect(() => {
        document.title = "Leagues - Mass Destruction";

        getAllLeagues()
            .then((l) => {
                if (l != null) {
                    setLeagues(l)
                }
            });
    }, []);

    const getLeagueCards = () => {
        leagues.sort(function (a: LeaguePreview, b: LeaguePreview) {
            return Date.parse(b.start_date) - Date.parse(a.start_date);
        });
        return leagues.map((league) => (
                < LeagueCard
                    key={league.id}
                    id={league.id}
                    title={league.title}
                    owner={league.owner}
                    startDate={new Date(league.start_date)}
                    endDate={new Date(league.end_date)}
                />
            )
        )
    }

    return (
        <ChakraProvider>
            <Container maxW='container.xl'>
                <Stack py={2}>
                    <Flex align='end' gap={2}>
                        <Navigation path={[{name: "Leagues", href: "."}]}/>
                        {
                            isLoggedOn === true &&
                            <>
                                <IconButton
                                    variant='outline'
                                    size='sm'
                                    aria-label='Create new League'
                                    colorScheme='teal'
                                    icon={<AddIcon/>}
                                    onClick={onOpen}/>
                                <CreateEventModal isOpen={isOpen} onClose={onClose}/>
                            </>
                        }
                    </Flex>
                    <Divider/>
                </Stack>
                <SimpleGrid columns={1} spacingY={2}>
                    {getLeagueCards()}
                </SimpleGrid>
            </Container>
        </ChakraProvider>
    )
}
