import React from "react";
import {ChakraProvider} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";

import {Header} from "../../components/Header/Header"

export const Layout: React.FC = () => {
    return (
        <ChakraProvider>
            <Header/>
            <Outlet/>
        </ChakraProvider>
    )
}
