import {BackendApi} from "./httpClient/backendApi";
import {Event} from "../../store/models";

export const startEvent = async (eventId: string): Promise<Event | null> => {
    const response = await BackendApi.getInstance().instance
        .post<{ event: Event }>(
            `/events/${eventId}/start`
        );

    if (!response.data) {
        return null;
    }

    return response.data.event;
}
