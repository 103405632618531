import React, {useEffect, useState} from 'react';
import {
    Flex,
    Box,
    Button,
    Heading,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import {
    Stack,
    NumberInput,
    NumberInputField,
} from '@chakra-ui/react'

import {Pod} from '../../../store/models'

interface PodProps {
    pod: Pod;
    isEditable?: boolean;
    updateScore: (pod: Pod | null) => void;
}

export const PodCard = ({pod, isEditable, updateScore}: PodProps) => {
    const [scores, setScores] = useState<Pod>(pod);

    useEffect(() => {
        setScores(pod)
    }, [pod])

    const getScoreInput = (
        onChange: (e: React.FormEvent<HTMLInputElement>) => void,
        defaultVal: number,
        min: number,
        max: number
    ) => {
        return (
            <NumberInput minW={14} variant='flushed' isReadOnly={!isEditable} defaultValue={defaultVal} min={min}
                         max={max}>
                <NumberInputField onChange={onChange}/>
            </NumberInput>
        )
    }

    const getTable = () => {
        return (
            <TableContainer>
                <Table variant='simple'>
                    <Tbody>
                        {
                            pod.players.map(
                                (player, index) =>
                                    <Tr key={player.username}>
                                        <Td> {player.username} </Td>
                                        <Td>
                                            <Flex gap={2} align='center' justify='space-between'>
                                                {
                                                    getScoreInput(
                                                        (e: React.FormEvent<HTMLInputElement>) => {
                                                            let copy: Pod = {...scores}
                                                            copy.players[index].score.points = Math.max(0, Math.min(4, Number(e.currentTarget.value)))
                                                            setScores(copy)
                                                        },
                                                        player.score.points,
                                                        0,
                                                        4
                                                    )
                                                }
                                                {
                                                    getScoreInput(
                                                        (e: React.FormEvent<HTMLInputElement>) => {
                                                            let copy: Pod = {...scores}
                                                            copy.players[index].score.tiebreaks = Math.max(0, Math.min(4, Number(e.currentTarget.value)))
                                                            setScores(copy)
                                                        },
                                                        player.score.tiebreaks,
                                                        0,
                                                        4
                                                    )
                                                }
                                            </Flex>
                                        </Td>
                                    </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={5} borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Stack spacing={2}>
                <Heading size='md'>Pod {pod.number}</Heading>
                {getTable()}
                {isEditable && <Button colorScheme='teal' onClick={() => {
                    updateScore(scores)
                }}>
                    Submit
                </Button>}
            </Stack>
        </Box>
    )
}
