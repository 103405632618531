import React from 'react';
import {useEffect, useState} from 'react'
import {ChakraProvider} from "@chakra-ui/react";
import {
    Button,
    Container,
    Divider,
    Flex,
    SimpleGrid,
    Stack
} from "@chakra-ui/react";
import {useLoaderData} from "react-router-dom";

import {StandingsCard} from "../../components/Main/StandindgsCard/StandingsCard";
import {getEvent} from "../../usecases/backend/getEvent";
import {RoundsCard} from "../../components/Main/RoundsCard/RoundsCard";
import {startEvent} from "../../usecases/backend/startEvent";
import {finishEvent} from "../../usecases/backend/finishEvent";
import {Navigation} from "../../components/Navigation/Navigation";
import {Event} from "../../store/models";
import {Restricted} from "../../utils/PermissionProvider/Restricted";
import {PermissionProvider} from "../../utils/PermissionProvider/PermissionProvider";
import useAuth from "../../utils/AuthProvider/useAuth";

export const EventPage: React.FC = () => {
    const id = useLoaderData() as string;
    const [event, setEvent] = useState<Event>();
    const auth = useAuth();

    const updateEvent = (event: Event | null) => {
        if (event)
            setEvent(event)
    }

    useEffect(() => {
        document.title = "Event - Mass Destruction";
        getEvent(id)
            .then(updateEvent)
    }, [id]);

    const getStandingCard = () => {
        if (!event) {
            return null
        }

        return (
            <StandingsCard
                eventId={event.id}
                standings={event.standings}
                mode={
                    event.status === 'created' ?
                        'input' :
                        event.status === 'started' ?
                            'running' :
                            'preview'
                }
                updateEvent={updateEvent}
            />
        )
    }

    const getRoundsCard = () => {
        if (!event) {
            return null
        }

        return <RoundsCard
            eventId={event.id}
            rounds={event.rounds}
            isEditable={event.status === "started"}
            updateEvent={updateEvent}
        />
    }

    return (
        <ChakraProvider>
            <PermissionProvider fetchPermission={(perm) => {
                console.log(auth.user?.username)
                switch (perm) {
                    case 'owner': {
                        return Promise.resolve(!!event && event?.owner === auth.user?.username);
                    }
                    default: {
                        return Promise.resolve(false);
                    }
                }
            }}>
                <Container maxW='container.xl'>
                    <Stack py={2}>
                        <Flex align='end' gap={2}>
                            {
                                event
                                    ? <Navigation path={[{name: "Events", href: "/"}, {name: event.title, href: "."}]}/>
                                    : <Navigation path={[{name: "Events", href: "/"}]}/>
                            }
                        </Flex>
                        <Divider/>
                    </Stack>
                    <Stack spacing={2}>
                        <Restricted to='owner'>
                            {
                                event?.status === "created" &&
                                <Button colorScheme='green' onClick={() => {
                                    startEvent(id).then(updateEvent)
                                }}>
                                    Start
                                </Button>
                            }
                            {
                                event?.status === "started" &&
                                <Button colorScheme='green' onClick={() => {
                                    finishEvent(id)
                                        .then(updateEvent)
                                        .catch((error) => {
                                            alert(error.response.data.detail)
                                        })
                                }}>
                                    Finish
                                </Button>
                            }
                        </Restricted>
                        <SimpleGrid spacing={2}>
                            {getStandingCard()}
                            {!!event?.rounds.length && getRoundsCard()}
                        </SimpleGrid>
                    </Stack>
                </Container>
            </PermissionProvider>
        </ChakraProvider>
    )
}
