import {BackendApi} from "./httpClient/backendApi";
import {Event, Round} from "../../store/models";

export const updateRoundResults = async (eventId: string, results: Round): Promise<Event | null> => {
    const response = await BackendApi.getInstance().instance
        .put<{ event: Event }>(
            `/events/${eventId}/rounds`,
            {...results},
        );

    if (!response.data) {
        return null;
    }

    return response.data.event;
}
