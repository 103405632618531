import {BackendApi} from "./httpClient/backendApi";
import {EventPreview} from "../../store/models";

export const getAllEvents = async (): Promise<EventPreview[] | null> => {
    const response = await BackendApi.getInstance().instance
        .get<{ events: EventPreview[] }>(
            `/events`,
        );

    if (!response.data) {
        return null;
    }

    return response.data.events;
}
