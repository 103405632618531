import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Badge,
    IconButton,
    FormControl,
    FormErrorMessage,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Input,
    InputGroup,
} from '@chakra-ui/react'
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import {Field, Form, Formik, FormikHelpers, FormikProps} from "formik";

import {Event, Standing} from "../../../store/models";
import {addPlayerToEvent} from "../../../usecases/backend/addPlayerToEvent";
import {dropPlayerFromEvent} from "../../../usecases/backend/droppPlayerFromEvent";
import {removePlayerFromEvent} from "../../../usecases/backend/removePlayerFromEvent";
import {Restricted} from "../../../utils/PermissionProvider/Restricted";

type Mode = "input" | "running" | "preview";

interface StandingsCardProps {
    eventId: string;
    standings: Standing[];
    mode: Mode;
    updateEvent: (event: Event | null) => void;
}

export const StandingsCard: React.FC<StandingsCardProps> = (
    {eventId, standings, mode, updateEvent}
) => {
    const getAddPlayerForm = () => {
        interface FormValues {
            playerName: string
        }

        return (
            <Formik
                initialValues={{playerName: ''}}
                onSubmit={(
                    values: FormValues,
                    {resetForm, setSubmitting}: FormikHelpers<FormValues>
                ) => {
                    addPlayerToEvent(eventId, values.playerName.trim()).then(updateEvent);
                    setSubmitting(false);
                    resetForm();
                }}
            >
                {(props: FormikProps<FormValues>) => (
                    <Form>
                        <Field name='playerName'>
                            {({field, form}: any) => (
                                <FormControl isRequired>
                                    <InputGroup>
                                        <HStack my={-4}>
                                            <Input minW={20} {...field} variant='flushed' placeholder='Player'/>
                                            <IconButton
                                                variant='ghost' size='sm' aria-label='Create new Event'
                                                colorScheme='teal'
                                                icon={<AddIcon/>}
                                                isLoading={props.isSubmitting}
                                                type='submit'
                                            />
                                        </HStack>
                                    </InputGroup>
                                    <FormErrorMessage>{form.errors.eventName}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        )
    }

    const getTable = () => {
        return (
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th isNumeric>Standing</Th>
                            <Th>Player</Th>
                            <Th>Status</Th>
                            <Th isNumeric>Points</Th>
                            <Th isNumeric>Tiebreaks</Th>
                            <Th isNumeric>OMWP</Th>
                            <Restricted to='owner'>
                                {mode !== 'preview' && <Th></Th>}
                            </Restricted>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            standings.map((standing) => {
                                return (
                                    <Tr key={standing.place}>
                                        <Td isNumeric>{standing.place}</Td>
                                        <Td>{standing.username}</Td>
                                        <Td>{
                                            standing.dropped ?
                                                <Badge colorScheme='red' variant='solid'>dropped</Badge> :
                                                <Badge colorScheme='green' variant='solid'>playing</Badge>
                                        }</Td>
                                        <Td isNumeric>{standing.score.points}</Td>
                                        <Td isNumeric>{standing.score.tiebreaks}</Td>
                                        <Td isNumeric>{(standing.opponents_mwp * 100).toFixed(2)}%</Td>
                                        {
                                            mode !== 'preview' &&
                                            <Restricted to='owner'>
                                                <Td textAlign='center'>
                                                    {
                                                        !standing.dropped && mode === 'running' && <IconButton
                                                            variant='ghost' size='xs' aria-label='Drop Player'
                                                            colorScheme='teal'
                                                            icon={<CloseIcon boxSize="0.8em"/>}
                                                            borderRadius='50%'
                                                            onClick={() => {
                                                                dropPlayerFromEvent(eventId, standing.username).then(updateEvent)
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        mode === 'input' && <IconButton
                                                            variant='ghost' size='xs' aria-label='Remove Player'
                                                            colorScheme='teal'
                                                            icon={<CloseIcon boxSize="0.8em"/>}
                                                            borderRadius='50%'
                                                            onClick={() => {
                                                                removePlayerFromEvent(eventId, standing.username).then(updateEvent)
                                                            }}
                                                        />
                                                    }
                                                </Td>
                                            </Restricted>
                                        }
                                    </Tr>
                                )
                            })
                        }
                        {
                            mode === 'input' &&
                            <Restricted to='owner'>
                                <Tr>
                                    <Td isNumeric>{standings.length + 1}</Td>
                                    <Td> {getAddPlayerForm()} </Td>
                                    <Td><Badge colorScheme='green' variant='solid'>playing</Badge></Td>
                                    <Td isNumeric>0</Td>
                                    <Td isNumeric>0</Td>
                                    <Td isNumeric>0%</Td>
                                    <Td></Td>
                                </Tr>
                            </Restricted>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Card variant='outline'>
            <CardHeader>
                <Heading size='md'>Standings</Heading>
            </CardHeader>
            <CardBody>
                {getTable()}
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    )
}
