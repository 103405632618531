import {BackendApi} from "./httpClient/backendApi";
import {LeaguePreview} from "../../store/models";

export const getAllLeagues = async (): Promise<LeaguePreview[] | null> => {
    const response = await BackendApi.getInstance().instance
        .get<{ leagues: LeaguePreview[] }>(
            `/leagues`,
        );

    if (!response.data) {
        return null;
    }

    return response.data.leagues;
}
