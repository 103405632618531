import {HttpClient} from "./httpClient"

const apiUrl: string = `${process.env.REACT_APP_API_ADDR}/api/v1`;

export class BackendApi extends HttpClient {
    private static classInstance?: BackendApi;

    private constructor() {
        super(apiUrl);

        this.instance.defaults.withCredentials = true;

        this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            null,
            this._handleError,
        );
    };

    private async refreshAccessToken() {
        await this.instance.post(
            '/auth/refresh',
        )
    }

    protected _handleError = async (error: any) => {
        const {config} = error;

        if (!config) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !config?.sent) {
            config.sent = true;
            await this.refreshAccessToken();
            return this.instance.request(config);
        }
        return Promise.reject(error);
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new BackendApi();
        }

        return this.classInstance;
    }
}