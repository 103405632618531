import React from "react";
import ReactDOM from "react-dom/client";
import {
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";
import "./index.css";

import {EventPage} from "./routes/EventPage/EventPage"
import {Layout} from "./routes/Layout/Layout"
import {LoginPage} from "./routes/LoginPage/LoginPage"
import {RootPage} from "./routes/RootPage/RootPage";
import {AuthProvider} from "./utils/AuthProvider/AuthProvider";
import {LeaguePage} from "./routes/LeaguePage/LeaguePage";
import {LeaguesPage} from "./routes/LeaguesPage/LeaguesPage";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<Layout/>}
        >
            <Route
                path="login"
                element={<LoginPage/>}
            />
            <Route
                path="/"
                element={<RootPage/>}
            />
            <Route
                path="leagues"
                element={<LeaguesPage/>}
            />
            <Route
                path="leagues/:leagueId"
                element={<LeaguePage/>}
                loader={({params}) => {
                    return params.leagueId;
                }}
            />
            <Route
                path="events/:eventId"
                element={<EventPage/>}
                loader={({params}) => {
                    return params.eventId;
                }}
            />
        </Route>
    )
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
    </React.StrictMode>
);
