import React from "react";
import {useEffect, useState} from "react"
import {ChakraProvider, useDisclosure} from "@chakra-ui/react";
import {Container, Divider, Flex, IconButton, SimpleGrid, Stack} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";

import {EventPreview} from "../../store/models";
import {EventCard} from "../../components/Main/EventCard/EventCard";
import {CreateEventModal} from "../../components/Main/CreateEventModal/CreateEventModal";
import {getAllEvents} from "../../usecases/backend/getAllEvents";

import {Navigation} from "../../components/Navigation/Navigation";
import useAuth from "../../utils/AuthProvider/useAuth";

export const RootPage: React.FC = () => {
    const [events, setEvents] = useState<EventPreview[]>([]);
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isLoggedOn} = useAuth();

    useEffect(() => {
        document.title = "Events - Mass Destruction";

        getAllEvents()
            .then((e) => {
                if (e != null) {
                    setEvents(e)
                }
            });
    }, []);

    const getEventCards = () => {
        events.sort(function (a: EventPreview, b: EventPreview) {
            return Date.parse(b.created_at) - Date.parse(a.created_at);
        });

        return events.map((event) => (
                <EventCard
                    key={event.id}
                    id={event.id}
                    title={event.title}
                    owner={event.owner}
                    createdAt={new Date(event.created_at)}
                    status={event.status}
                />
            )
        )
    }

    return (
        <ChakraProvider>
            <Container maxW='container.xl'>
                <Stack py={2}>
                    <Flex align='end' gap={2}>
                        <Navigation path={[{name: "Events", href: "."}]}/>
                        {
                            isLoggedOn === true &&
                            <>
                                <IconButton
                                    variant='outline'
                                    size='sm'
                                    aria-label='Create new Event'
                                    colorScheme='teal'
                                    icon={<AddIcon/>}
                                    onClick={onOpen}/>
                                <CreateEventModal isOpen={isOpen} onClose={onClose}/>
                            </>
                        }
                    </Flex>
                    <Divider/>
                </Stack>
                <SimpleGrid columns={1} spacingY={2}>
                    {getEventCards()}
                </SimpleGrid>
            </Container>
        </ChakraProvider>
    )
}
